/** @jsx jsx */
import { jsx } from "theme-ui"
import Img from "gatsby-image"
import Layout from "../layout"
import SEO from "../seo"

const News = props => {
  return (
    <Layout>
      <SEO title={`${props?.posts?.title} | tinyML Foundation`} />
      {props?.posts?.postLink?.postImage?.localFile?.childImageSharp?.fluid ? (
        <div className="grid-container gap-xl">
          <div
            sx={{
              maxWidth: `50rem`,
              m: `0 auto`,
              pt: `140px`,
              "@media (max-width: 768px)": { pt: `85px` },
            }}
          >
            <Img
              sx={{ maxHeight: `500px` }}
              fluid={
                props?.posts?.postLink?.postImage?.localFile?.childImageSharp
                  ?.fluid
              }
              alt="conference"
            />
          </div>
        </div>
      ) : null}

      <div className="grid-container gap-xxxl posts">
        <div sx={{ maxWidth: `50rem`, m: `0 auto` }}>
          {props?.posts?.title ? (
            <h1 sx={{ textAlign: `center` }}>{props?.posts?.title}</h1>
          ) : null}
          {props?.posts?.date || props?.posts?.author?.node?.name ? (
            <div
              sx={{ textAlign: `center`, fontSize: `0.8rem` }}
              className="gap-l"
            >
              {props?.posts?.date}
            </div>
          ) : null}
          {props?.posts?.postLink?.postContent ? (
            <div
              dangerouslySetInnerHTML={{
                __html: props?.posts?.postLink?.postContent,
              }}
            ></div>
          ) : null}
        </div>
      </div>
    </Layout>
  )
}

export default News
