import React from "react"
import { graphql } from "gatsby"
import Posts from "../components/Blog/index"
import Footer from "../components/footer"
import Layout from "../components/layout"

const Post = props => {
  return (
    <Layout>
      <Posts posts={props.data.wpPost} />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String) {
    wpMenu(name: { eq: "Main Menu" }) {
      menuItems {
        nodes {
          label
          childItems {
            nodes {
              label
              url
            }
          }
        }
      }
    }
    allWpMenu {
      nodes {
        menuItems {
          nodes {
            childItems {
              nodes {
                label
                url
              }
            }
            label
            url
          }
        }
      }
    }
    wpPost(slug: { eq: $slug }) {
      id
      slug
      uri
      author {
        node {
          name
        }
      }
      excerpt
      postLink {
        postImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        postExcerpt
        postContent
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      title
      categories {
        nodes {
          id
          name
        }
      }
      date(formatString: "MMMM DD, YYYY")
      content
    }
  }
`

export default Post
